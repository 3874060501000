<template>
    <pagination title="People" :details-open="viewUser" :entities="users" :filterAction="filterAction" headerColourClass="bg-transparent" :with-alt-view-icon="true" @altViewClicked="altViewClicked" :response="response" :activeClass="activeClass">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Name
            </th>
<!--            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-v3-gray-600">Status</th>-->
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Role</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': viewUser}">Department</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': viewUser}">Start Date</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': viewUser}">Phone</th>
<!--            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-v3-gray-600">Company</th>-->
        </template>
        <template v-slot:items>
            <tr v-for="user in users" @click="openUserDetails(user)" class="cursor-pointer" :class="{[activeClass]:openId !== null && user.entity.properties.get('id') === openId}">
                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-xs font-medium sm:pl-6">
                    <div class="flex items-center">
                        <profile-image dimensionsClass="w-8 h-8" fontSizeClass="text-sm" :profileImage="user.entity.properties.get('profile_image')" :initials="getInitials(user)"></profile-image>
                    <div>
                        {{user.entity.properties.get('name')}}<br>
                        <span class="text-v3-gray-600 dark:text-v3-gray-400">{{user.entity.properties.get('email')}}</span>
                    </div>
                    </div>
                </td>
<!--                <td class="whitespace-nowrap px-3 py-2 text-sm text-v3-gray-800">On Site</td>-->
                <td class="whitespace-normal px-3 py-2 text-xs">
                    {{user.entity.properties.get('roles').replaceAll(',',', ')}}
                </td>
                <td class="whitespace-nowrap px-3 py-2 text-xs" :class="{'hidden': viewUser}">
                    {{user.entity.properties.get('department')}}
                </td>
                <td class="whitespace-nowrap px-3 py-2 text-xs" :class="{'hidden': viewUser}">
                    {{user.entity.properties.get('contract_start_date')}}
                </td>
                <td class="whitespace-nowrap px-3 py-2 text-xs" :class="{'hidden': viewUser}">
                    {{user.entity.properties.get('contact_number')}}
                </td>
<!--                <td class="whitespace-nowrap px-3 py-2 text-sm text-v3-gray-800"></td>-->
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import Siren from "super-siren";
import ProfileImage from "@/v3/components/ProfileImage.vue";

export default {
  name: 'HrPeoplePagination',
    data(){
      return {
        viewUser: false,
          userDetails: null,
          singleUserDetailsOpen: false,
          openId: null
      }
    },
    components: {ProfileImage, HrSinglePersonPagination, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        initials() {
            if (this.userDetails && this.userDetails.properties['name']) {
                return this.userDetails.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        }
    },
  props: {
    users: {},
      response: {
        type: Object,
          default: null
      },
      page:{
        type: String,
          default: 'hr-people'
      },
      activeClass:{
          type: String,
          default: null
      }
  },
    methods:{
      openUserDetails(userEntity){
          this.$emit('setLoading');
          this.userDetails = false;
          this.viewUser = true;
          this.getUserDetails(userEntity);
      },
        getUserDetails(userEntity){
            this.openId = userEntity.entity.properties.get('id');
            let selfLink = userEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );
            Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                this.$emit('setUserDetails', response.body);
            }).catch(function(){

            });
        },
        closeDetails(){
          this.userDetails = false;
          this.viewUser = false;
        },
        altViewClicked(){
          this.$emit('altViewClicked');
          this.viewUser = false;
          this.openId = null;
        },

        getInitials(user){
            if (user && user.entity.properties.get('name')) {
                return user.entity.properties.get('name')
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        }
    }
}
</script>
