<template>
    <div>
        <custom-button  v-if="isButton" class="mr-3" :class="additionalButtonClasses" @click.native="displayModal = true">Review Quotes</custom-button>
        <a v-else class="cursor-pointer font-normal underline text-gray-400"  @click="displayModal = true">Review Quotes</a>
        <portal to="destination">
            <review-quotes-modal v-if="displayModal" title="Review Quotes" confirmationString="" secondButtonTitle="Accept Competing Quote" firstButtonTitle="Accept Original Quote" @removeFromDisplay="displayModal = false" @perform="perform" @performSecond="performSecond">
                <div class="grid grid-cols-2">
                    <provisional-quote :response="response" :displayAsTable="false"></provisional-quote>
                    <competing-quote :response="response"></competing-quote>
                </div>
            </review-quotes-modal>

        </portal>
        <portal to="destination">
            <modal v-if="displayRejectModal" :submitting="submittingModal" title="Accept Original Quote"
                   confirmationString='Do you want to leave CMS a rejection note?'
                   @removeFromDisplay="displayRejectModal = false" @perform="performRejectAction()"
                   :isNegativeAction="isNegativeAction" :isPositiveAction="isPositiveAction"
                   :action="firstAction">
                <template v-for="(field, index) in firstAction.fields">
                    <component class="mt-2" :is="getFieldComponent(field[1])" :field="field[1]" v-model="formData[field[1].name]">
                    </component>
                    <div v-show="errors.has(field[1].name)" class="text-red-600">{{ errors.first(field[1].name) }}</div>
                </template>
            </modal>
        </portal>

        <portal to="destination">
            <modal v-if="displayAcceptModal" :submitting="submittingAcceptModal" :title="secondAction.title" :confirmationString='secondConfirmationString' @removeFromDisplay="displayAcceptModal = false" @perform="performApproveAction()" :isNegativeAction="isNegativeAction" :isPositiveAction="isPositiveAction"></modal>

        </portal>
    </div>
</template>

<script>
    import Modal from "@/v3/components/modals/Modal.vue";
    import ProvisionalQuote from "@/components/ProvisionalQuote";
    import CompetingQuote from "@/components/CompetingQuote";
    import ReviewQuotesModal from "@/v3/components/modals/ReviewQuotesModal.vue";
    import SlideOver from "@/components/SlideOver";
    import fileDownload from "js-file-download";
    import ActionWithConfirmation from "@/components/ActionWithConfirmation";
    import CustomButton from "@/v3/Buttons/CustomButton.vue";
    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                displayAcceptModal: false,
                displayRejectModal: false,
                submittingAcceptModal: false
            }
        },
        components: {
            CustomButton,
            ReviewQuotesModal,
            CompetingQuote,
            ProvisionalQuote,
            Modal,
            SlideOver,
            ActionWithConfirmation
        },
        props: {
            response:{
                type: Object,
            },
            firstAction: {
                type: Object,
            },
            secondAction: {
                type: Object
            },
            properties:{
                type: Object
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            isButton:{
                type: Boolean,
                default:true
            },
            additionalButtonClasses:{
                type:String
            },
            isNegativeAction: {
                type:Boolean,
                default:false
            },
            isPositiveAction:{
                type:Boolean,
                default:false
            }
        },
        computed: {
            finalConfirmationString() {
                var preText = '';

                return preText + `Are you sure you want to "Review Quotes"`
            },
            firstConfirmationString() {
                var preText = '';
                if (this.action.name === 'request-update'){
                    if (this.properties.get('last_updated_by_name') !== null && this.properties.get('last_updated_by_date') !== null)
                        preText = this.properties.get('last_updated_by_name') + ' requested an update ' +  this.properties.get('last_updated_by_date') + ', ';
                }

                return preText + `Are you sure you want to "${this.firstAction.title}"`
            },
            secondConfirmationString() {
                var preText = '';
                if (this.secondAction.name === 'request-update'){
                    if (this.properties.get('last_updated_by_name') !== null && this.properties.get('last_updated_by_date') !== null)
                        preText = this.properties.get('last_updated_by_name') + ' requested an update ' +  this.properties.get('last_updated_by_date') + ', ';
                }

                return preText + `Are you sure you want to "${this.secondAction.title}"`
            },
            formData() {
                if (!this.firstAction.fields) {
                    return {};
                }
                var payload = {};
                this.firstAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
        },
        methods: {
            getFieldComponent(field) {
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                this.displayRejectModal=true;
            },
            performSecond(){
                this.displayAcceptModal= true;
            },
            performRejectAction() {
                this.submittingModal = true;
                this.firstAction.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.displayRejectModal = false;
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        let newPath = this.onSuccessPath;
                        let fullPath = this.$router.currentRoute.fullPath;

                        if(newPath  !== fullPath) {
                            this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        }else{
                            this.$router.go(0);
                        }
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            performApproveAction() {
                this.submittingAcceptModal = true;
                this.secondAction.perform().then(res => {
                    this.displayAcceptModal = false;
                    this.displayModal = false;
                    this.submittingAcceptModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        let newPath = this.onSuccessPath;
                        let fullPath = this.$router.currentRoute.fullPath;

                        if(newPath  !== fullPath) {
                            this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        }else{
                            this.$router.go(0);
                        }
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    alert('There was an error processing your request.' + error);
                    this.displayAcceptModal = false;
                    this.submittingAcceptModal = false;
                    this.$emit('failure');
                });
            },
            messages(response) {
            return response.body.entities
                .filter(function(entity) {
                return entity.rels.contains("status-messages");
                })
                .first();
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
