<template>
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>

                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>Procure</template>
            <template v-slot="slotProps">
            <fetch-siren-root :linkName='linkName' :link="link">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">

                        <div class="flex w-full overflow-hidden">
                            <div class="p-2 h-full">
                                <procurement-navigation :links="slotProps.links"></procurement-navigation>
                            </div>
                            <div class="flex w-full h-full p-2 overflow-hidden">
                                <div class="rounded-xl bg-v3-white-100 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden" :class="{'grid grid-cols-3': details || loadingDetails}">
                                    <loading v-if="!response"></loading>
                                    <template v-else>
                                        <div class="p-2 overflow-y-auto h-full" :class="{'hidden': hideTable}">
                                            <schedule-manager-pagination :response="response" :orders="getOrders(response)" @setDetails="showDetails" @setLoading="setLoading" @altViewClicked="altViewClicked"></schedule-manager-pagination>
                                        </div>
                                        <SingleOrderDetails ref="orderDetails" :details="details" :loadingDetails="loadingDetails" :hideTable="hideTable" @setLoading="setLoading" @getDetails="getDetails" @hideTable="toggleHideTable"/>
                                    </template>
                                </div>
                            </div>
                        </div>


                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import FetchSirenRoot from '@/components/FetchSirenRoot';
import HrPeoplePagination from "@/v3/components/pagination/HrPeoplePagination.vue";
import HrNavigation from "@/v3/components/navigation/HrNavigation.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import ProcurementNavigation from "@/v3/components/navigation/ProcurementNavigation.vue";
import ProcurementPagination from "@/v3/components/pagination/OrdersPagination.vue";
import ScheduleManagerPagination from "@/v3/components/pagination/ScheduleManagerPagination.vue";
import SingleOrderDetails from "@/v3/pages/SingleOrderDetails.vue";
import Siren from "super-siren";

export default {
    components: {
        SingleOrderDetails,
        ScheduleManagerPagination,
        ProcurementNavigation,
        ProcurementPagination,
        HrSinglePersonPagination,
        HrPeoplePagination,
        HrNavigation,
        FetchSirenRoot,
        Loading,
        MainTemplate,

    },
    data() {
        return {
            slotProps: null,
            users: null,
            details: null,
            singleUserDetailsOpen: false,
            loadingDetails: false,
            orders: null,
            hideTable: false
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        initials() {
            if (this.userDetails && this.userDetails.properties['name']) {
                return this.userDetails.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },

    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        getOrders(response) {
            if (!response) {
                return null;
            }
            return response.body.entities.filter(entity => entity.rels.contains('order'));
        },
        showDetails(details){
            this.$setLaravelValidationErrorsFromResponse('');
            this.details = details;
            this.loadingDetails = false;
        },
        setLoading(){
            this.$refs.orderDetails.editing = false;
            this.$refs.orderDetails.editingOrderItems = false;
            this.$refs.orderDetails.tabOpen = 'order-list';
            this.details = null;
            this.loadingDetails = true;
        },
        getDetails(entity){
            this.$refs.orderList.getDetails(entity);
        },
        toggleHideTable(){
            this.hideTable = !this.hideTable;
        },
        altViewClicked(){
            this.hideTable = false;
            this.details = null;
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=procure_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=procure_light"
        }
    }
}
</script>

